/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "VAROLUŞUN YEDİ SEVİYESİ"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Varoluş seviyesi: Cansız maddeler ,mineraller ,taş,toprak gibi maddelerin bulunduğu seviyedir."), "\n", React.createElement(_components.li, null, "Varoluş seviyesi : Bitkilerin bulunduğu seviyedir."), "\n", React.createElement(_components.li, null, "Varoluş seviyesi : İnsanlar,hayvanlar,bitkiler ve madenlerin,minerallerin birlikte bulunduğu bu seviye bizim şu anda yaşamakta olduğumuz seviyedir."), "\n", React.createElement(_components.li, null, "Varoluş seviyesi : Ruhların bulunduğu seviyedir. Ruhumuz bedenimizi terk ettikten sonra bu seviyeye geçiş yapar."), "\n", React.createElement(_components.li, null, "Varoluş seviyesi : Üstad ruhların , peygamberlerin,meleklerin bulunduğu seviyedir."), "\n", React.createElement(_components.li, null, "Varoluş seviyesi : Evrenin yasalarının bulunduğu seviyedir."), "\n", React.createElement(_components.li, null, "Varoluş seviyesi : Herşeyi yaratanın bulunduğu seviyedir. Bu seviyedeki akıllı enerji her şeyi kontrol eder . İnsanlar bu seviyeyi çeşitli şekillerde tanımlamışlardır. Dini inançlarına göre ALLAH,TANRI şeklinde isimlendirmişlerdir.Nasıl isimlendirilmiş olursa olsun O TEKTİR.Bir tane ve eşsizdir. Ruhumuz da onun enerjisinin bir parçasıdır."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
